import React, { useState, useEffect, useRef } from 'react';
import { categoryList, propertyDefs } from '../../PropertyDefs';
import { useDatabase } from '../../DatabaseProvider';
import './SidebarFilter.css'
import { useDeviceTypeContext } from '../../contexts/DeviceType/DeviceTypeContext.jsx';
import decimalToFraction from '../../util/decimalToFraction.js';
import { smartLog } from '../../util/smartLog.js';

const SidebarFilterComponent = ({ currentCategoryID, setFilterString, allEntries, setFilteredEntries, filteredEntries, selectedCategory, categoryPropertyDef }) => 
{
	if (!categoryPropertyDef) { 
		return null; 
	}

	const deviceType = useDeviceTypeContext();

	const db = useDatabase();

	const [filter, setFilter] = useState('');
	const [dropdownFilters, setDropdownFilters] = useState({});
	const filterInputRef = useRef(null);

	const catDef = categoryList.find(o => o.id === currentCategoryID);
	const extraFilterBy = catDef?.extraFilterBy;

	// Adding this to the DOM means we want it to be the default keyboard input
	useEffect(() => 
	{
		if (deviceType === "desktop")
		{
			// On a new category, jump focus to the filter input so user can start typing immediately
			filterInputRef.current.focus();
		}
		// And clear out the dropdown filters from previous
		setDropdownFilters({});	

	},[filterInputRef,selectedCategory]);

	// Handle the Escape key press
	useEffect(() => 
	{
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				// If the filter string is not empty, clear it.
				if (filter) {
					setFilter('');
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		// Cleanup the event listener on component unmount
		return () => 
		{
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [filter]);

	const applyDropdownFilter = (entry, key, filterValue) => {
		if (filterValue === 'undefined') {
			return entry[key] === undefined;
		}
	
		if (filterValue === '') return true;
	
		let entryValue;
		if (key === extraFilterBy) {
			entryValue = getValueFromPath(entry, key);
		} else {
			entryValue = entry[key];
		}
	
		const compEntry = String(entryValue);
		const compFilter = String(filterValue);

		// Compare as strings in case a number comes in as a string from the dropdown.
		return compEntry === compFilter;
	};

	useEffect(() => {
		setFilterString(filter);
		const escapedFilter = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const regex = new RegExp(escapedFilter, 'i');
		
		const entriesArray = Object.values(allEntries);
		
		const filteredArray = entriesArray.filter(entry => {
			// Check the text filter first
			const matchesFilter = regex.test(entry.name);
			
			// If there's no text filter match, return false early
			if (!matchesFilter) return false;
			
			// Now check dropdown filters
			return Object.entries(dropdownFilters).every(([key, value]) => {
				return applyDropdownFilter(entry, key, value);
			});
		});
		
		// Send the filtered array back to the sidebar
		setFilteredEntries(filteredArray);
	}, [filter, allEntries, dropdownFilters, setFilterString, setFilteredEntries]);

	const handleDropdownChange = (filterKey, value) => 
	{
		setDropdownFilters(prevFilters => ({
			...prevFilters,
			[filterKey]: value
		}));
	};

	const getFilteredOptions = (prop) => {
		let optionsSource = propertyDefs[prop.type] || Object.values(db.data[prop.type]);
	
		// If at least one filter is set, adjust options based on available entries
		const filtersSet = Object.values(dropdownFilters).some(value => value !== '' && value !== 'undefined');
		if (filtersSet) {
			optionsSource = optionsSource.filter(option => {
				return Object.entries(dropdownFilters).every(([key, value]) => {
					// Skip the current dropdown's filter
					if (key === prop.id) return true;
	
					const entriesWithThisOption = filteredEntries.filter(entry => entry[prop.id] === option.id);
					
					// Handle special case for 'undefined'
					if (value === 'undefined') {
						return entriesWithThisOption.some(entry => entry[key] === undefined);
					}
	
					// Normal filtering
					return value === '' || entriesWithThisOption.some(entry => entry[key] === value);
				});
			});
		}
	
		// Return the options without adding "Any" here
   		return [...optionsSource, { id: 'undefined', name: 'Undefined' }];
	};	
	
	const renderDropdowns = () => {
		if (extraFilterBy) {
			// Logic to handle extraFilterBy
			const extraFilterOptions = createExtraFilterOptions(extraFilterBy);
			return renderExtraFilterDropdown(extraFilterOptions, extraFilterBy);
		} else {
			// Render dropdowns for properties set with filterBy
			return categoryPropertyDef
				.filter(prop => prop && prop.filterBy)  // Ensure prop is defined and has filterBy
				.map((prop, index) => {
					if (!prop) {
						console.warn("Warning: prop is undefined");  
						return null; 
					}
					return renderFilterByDropdown(prop, index);
				});
		}
	};
	

	function createExtraFilterOptions(extraFilterBy) {
		// Logic to create options for extraFilterBy
		return Object.values(selectedCategory).map(entry => {
			const value = getValueFromPath(entry, extraFilterBy);
			const nm = (typeof value === 'number' && value < 1) ? decimalToFraction(value) : value
			return { name: nm, id: value }; // Adjust as per your data structure
		})
		.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) // Remove duplicates
		.sort((a, b) => {
			// Check if both are numbers
			if (typeof a.id === 'number' && typeof b.id === 'number') {
				return a.id - b.id;
			}
			// If not, convert both to strings and use localeCompare
			return String(a.id).localeCompare(String(b.id));
		});
	}
	
    function renderExtraFilterDropdown(options, extraFilterBy) {
        const optionsJSX = [
            <option key="any" value="">Any {extraFilterBy}</option>,
            ...options.map((option, index) => (
                <option key={option.id+index} value={option.id}>{option.name}</option>
            ))
        ];

        return (
            <select
                key={extraFilterBy}
                value={dropdownFilters[extraFilterBy] || ''}
                onChange={(e) => handleDropdownChange(extraFilterBy, e.target.value)}
                className="sidebar-filter-dropdown"
            >
                {optionsJSX}
            </select>
        );
	}

	const applyLabelOverride = (extraFilter, prop) =>
	{
		// Use the extraFilter if provided
		if (extraFilter) {
			return extraFilter;
		}

		// Check for a label override based on the id of the property
		let labelOverride = db.data.labels.find((label) => label.category === prop.id);
		if (labelOverride)
		{
			// console.log("labelOverride:",prop,"with",labelOverride)
			return labelOverride.name;
		}

		// Fallback to placeholder or name, whichever exists, favoring the placeholder nickname
		const term = prop.placeholder || prop.name;

		// Check for a label override using the fallback term
		labelOverride = db.data.labels.find((label) => label.category === term);
		if (labelOverride) {
			// console.log("labelOverride:",term,"with",labelOverride)
			return labelOverride.name;
		}

		// Return the term itself if no overrides are found
		return term;
	};

	function renderFilterByDropdown(prop, index) {
		const options = getFilteredOptions(prop);

		// TODO: Figure out a better way to do this where the options are ranks of the same property
		const rankName = prop.type == "rank" ? prop.name : "";
		const prefixName = prop.type == "rank" ? prop.name+" " : "";
		
		const optionsJSX = [
			<option key="any" value="">
				Any {rankName || applyLabelOverride(extraFilterBy,prop)}
			</option>,
			...options.map((option, index) => (
				<option key={option.id + index} value={option.id}>{prefixName + option.name}</option>
			))
		];

		const key = prop.id+index;

        return (
            <select
                key={key}
                value={dropdownFilters[prop.id] || ''}
                onChange={(e) => handleDropdownChange(prop.id, e.target.value)}
                className="sidebar-filter-dropdown"
            >
                {optionsJSX}
            </select>
        );
    }

    function getValueFromPath(obj, path) {
        const properties = path.split('.');

        let currentValue = obj;
        for (const property of properties) {
            if (currentValue[property] === undefined) {
                return undefined; // Or some default value
            }
            currentValue = currentValue[property];
        }
        return currentValue;
    }

    return (
        <>
            <input
                ref={filterInputRef}
                type="text"
                placeholder="Filter..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="sidebar-filter-text"
            />
            {renderDropdowns()}
        </>
    );
};

export default SidebarFilterComponent;