import { titleCase } from "./silcerUtils";
import * as util from "./silcerUtils";

export const renderCharacterDetails = (db, level, augmentPoints, updateModel, character) => {
    const archetypeDef = db.data.archetypes[character.statblock.archetype];
    const archetypeName = archetypeDef ? archetypeDef.name : `"No Archetype named: ${character.statblock.archetype}`;
    if (db.editMode) {
        return (
            <div className="character-details-edit">
                Level {level}
                <label>
                    Model:
                    <select
                        value={character.statblock.model}
                        onChange={(e) => updateModel(e.target.value)}
                    >
                        <option value="FV">FV</option>
                        <option value="PV">PV</option>
                        <option value="Mod">Mod</option>
                    </select>
                </label>
                {archetypeName}
                {displayAP(augmentPoints)}
            </div>
        );
    } else {
        return (
            <div className="character-details">
                Level {level} {character.statblock.model} {archetypeName}
                {displayAP(augmentPoints)}
            </div>
        );
    }
};

const displayAP = (augmentPoints) => {

    const apcolor = augmentPoints.spend > augmentPoints.budget ? "invalid-value" : "";
    return (
        <>
            <div className={apcolor}>AP: {augmentPoints.spend + "/" + augmentPoints.budget}</div>
        </>
    )

}

export const renderEditableSkill = ({
    skillDef,
    onUpdateSkillRank
}) => {
    const { skill, rank } = skillDef;

    return (
        <div className="stat-block-item editable" key={skill}>
            <label className="stat-name" htmlFor={`skill-${skill}`}>
                {titleCase(skill)}
            </label>
            <input
                id={`skill-${skill}`}
                type="number"
                min={1}
                max={6}
                value={rank}
                onChange={(e) => onUpdateSkillRank(skill, e.target.value)}
                className="skill-rank-input"
            />
        </div>
    );
};


export const renderRollableSkill = ({
    skillDef,
    character,
    db,
    resilienceValues,
    onRollResult,
    onTargetedRoll,
}) => {
    const { skill, rank } = skillDef;

    // Roll the dice and calculate the roll result
    const rollDice = () => {
        let die;
        switch (parseInt(rank)) {
            case 2: die = 6; break;
            case 3: die = 8; break;
            case 4: die = 10; break;
            case 5: die = 12; break;
            case 6: die = 20; break;
            default: die = 4;
        }

        let { total, rolls, exploded } = util.silcerDiceRoller(die);
        const augmentMods = util.accumulateActiveAugmentsForSkill(db, character, skill);
        const resilienceModifier = Math.min(0, resilienceValues[util.getResilienceBySkill(skill)] || 0);

        total += augmentMods + resilienceModifier;

        const rollDef = {
            timestamp: Date.now(),
            id: `${skill}-${character.name}`,
            silcer: {
                rollType: "attack",
                skill: skill,
                count: rolls.length,
                dice: rolls,
            },
            name: titleCase(skill),
            die,
            mod: augmentMods + resilienceModifier,
            exploded,
            total,
            rollerName: character.name,
            roller: character,
        };

        return rollDef; // Return the roll result
    };

    const handleRollClick = () => {
        const rollDef = rollDice();
        onRollResult(rollDef); // Send to onRollResult callback
    };

    const handleTargetedRollClick = (e) => {
        e.stopPropagation(); // Prevent triggering the parent click
        const rollDef = rollDice();
        onTargetedRoll(rollDef); // Send to onTargetedRoll callback
    };

    return (
        <div className="stat-block-item" onClick={handleRollClick} key={skill}>
            <span className="stat-name">{titleCase(skill)}</span>
            <div className="stat-bottom-row">
                <div className="stat-dice">{rank}</div>
                <div
                    className="roll-vs-button"
                    onClick={handleTargetedRollClick}
                    key={`${skill}-vs`}
                >
                    ∴
                </div>
            </div>
        </div>
    );
};
