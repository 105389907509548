import React, { useEffect } from "react";
import './SkillColumn.css';

const SkillColumn = ({
    skill,
    scrollRefs,
    scrollPositions,
    onScroll,
    contentRenderer, // Function to render content inside the column
}) => {
    const skillId = skill.id;

    useEffect(() => {
        const scrollContainer = scrollRefs.current[skillId];
        const savedScrollTop = scrollPositions.current[skillId];
        if (scrollContainer && savedScrollTop !== undefined) {
            scrollContainer.scrollTop = savedScrollTop; // Restore scrollTop
        }
    }, [skillId, scrollRefs, scrollPositions]);

    return (
        <div className="skill-column">
            <div className="skill-header">{skill.name}</div>
            <div
                className="skill-content"
                onScroll={(e) => onScroll(e, skillId)}
                ref={(el) => {
                    if (el) {
                        scrollRefs.current[skillId] = el; // Store DOM reference
                    }
                }}
            >
                {contentRenderer(skillId)}
            </div>
        </div>
    );
};

export default SkillColumn;
