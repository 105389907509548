import React, { useRef, useEffect, useState, useContext } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { openDB } from 'idb';
import './Encounter.css';
import { RollHistoryContext } from '../../contexts/RollHistory/RollHistoryContext.jsx';
import DiceRendererComponent from '../DiceRenderer/DiceRenderer.jsx';
import { generateUniqueID } from '../../UniqueID.js';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown.jsx';
import { generateDropdownOptions } from '../Entry/generateDropdownOptions.js';
import CharacterButton from './CharacterButton.jsx';
import ButtonNav from '../Buttons/ButtonNav.jsx';

const EncounterComponent = ({ encounterDef }) => {

	const db = useDatabase();

	const { rollHistory, clearRollHistory } = useContext(RollHistoryContext);

	const [encounter, setEncounter] = useState(encounterDef);
	const [isLoading, setIsLoading] = useState(true);

	const databaseName = 'myDatabase'; // You should use a relevant database name
	const storeName = 'encounters'; // Object store name for encounters
	
	const rollHistoryRef = useRef(null);

	useEffect(() => {
		if (rollHistoryRef.current) {
			rollHistoryRef.current.scrollTop = rollHistoryRef.current.scrollHeight;
			rollHistoryRef.current.scrollLeft = rollHistoryRef.current.scrollWidth;
		}
	}, [rollHistory]);

	// Open (and create, if necessary) an IndexedDB database
	const initDB = async () => {
		const idb = await openDB(databaseName, 1, {
			upgrade(idb) {
				// Create a new object store or open existing
				if (!idb.objectStoreNames.contains(storeName)) {
					idb.createObjectStore(storeName, { keyPath: 'id' });
				}
			},
		});
		return idb;
	};

	const resetEncounter = () => {
		if (confirm("Reset entire encounter?"))
		{
			_resetEncounter();
		}
	}

	const duplicateEncounter = () => {
		const dupe = JSON.parse(JSON.stringify(encounter));
		dupe.id = generateUniqueID();
		dupe.name += " (copy)";
		db.addEncounter(dupe); 
	}

	const removeCharacter = (chardef) => {
		const newEncounter = JSON.parse(JSON.stringify(encounter));
		const entryInArray = newEncounter.characters.findIndex(o=>o.id===chardef.id);
		if (entryInArray === -1)
		{
			console.error("Can't find in array",chardef.id);
			return;
		}
		newEncounter.characters.splice(entryInArray,1);
		setEncounter(newEncounter);
	}
	
	const pickMonster = (monsterID) => {
		// When we add a monster, completely reset the encounter so it re-populates with the new character
		// TODO: Inject the new character, leaving the others intact with their current values
		_resetEncounter();	

		db.addMonsterToEncounter(monsterID, encounter.id);
	}

	// Add the resetEncounter function using IndexedDB
	const _resetEncounter = async () => {
		const idb = await initDB();
		// Remove the encounter from IndexedDB
		await idb.delete(storeName, encounterDef.id); 
		// Reset encounter state to initial encounterDef
		encounterDef.currentCharacterIndex = 0;
		encounterDef.currentRound = 0;
		setEncounter(encounterDef); 
		console.log("resetEncounter to",encounterDef)
	};


	useEffect(() => {
		const loadEncounter = async () => {
		const idb = await initDB();
		let savedEncounter = await idb.get(storeName, encounterDef.id);
		if (savedEncounter) {
			// If an encounter was found
			// console.log("Got saved encounter:",savedEncounter);
			setEncounter(savedEncounter);
		} else {
			// If no encounter was found, create a new one
			savedEncounter = { ...encounterDef};
			await idb.put(storeName, savedEncounter);
			setEncounter(savedEncounter);
		}
		setIsLoading(false); // Data is loaded, update loading state
		};
	
		loadEncounter();
	}, []);
	

	const initialLoad = useRef(true);

	useEffect(() => {
		// console.log(encounter)
		const saveEncounter = async () => {
			// Don't save immediately after initial load
			if (initialLoad.current) {
				initialLoad.current = false;
				return;
			}
			const idb = await initDB();
			await idb.put(storeName, encounter);
			// And save to db
			const path = ['encounters',encounter.id];
			db.updateDB(path, encounter);
		};

		// Add some debouncing to avoid saving too rapidly in succession
		const handler = setTimeout(() => {
			if (encounter) {
				saveEncounter();
			}
		}, 500);

		// Cleanup function to cancel save if the component unmounts
		return () => {
			clearTimeout(handler);
		};
	}, [encounter]);

	const advanceTurn = (dir) => {
		const newEnc = {...encounter}
		if (newEnc.currentCharacterIndex == null) { newEnc.currentCharacterIndex = 0; }
		if (newEnc.currentRound == null) { newEnc.currentRound = 0; }

		newEnc.currentCharacterIndex += dir;
		if (newEnc.currentCharacterIndex < 0) 
		{
			if (newEnc.currentRound > 0) { newEnc.currentRound --; } 
			newEnc.currentCharacterIndex = newEnc.characters?.length-1; 
		}
		if (newEnc.currentCharacterIndex >= newEnc.characters?.length) 
		{
			newEnc.currentRound ++;
			newEnc.currentCharacterIndex = 0; 
		}
		setEncounter(newEnc)
	}

	const characterButtons = () => {
		if (!encounter.characters) { return null; }
		if (encounter.characters.length === 0) { return null; }
		
		// First, sort the characters by their 'init' in descending order
		const sortedCharacters = [...encounter.characters].sort((a, b) => b.init - a.init);
		
		// Check if there is a current character index; if not, start with 0
		const startIndex = encounter.currentCharacterIndex ?? 0;
		
		// Find the index of the current character in the sorted array
		const currentIndexInSorted = sortedCharacters.findIndex(
			(char) => char.id === sortedCharacters[startIndex].id
		);
			
		// Create an array that starts with the current character and then adds the rest
		const orderedCharacters = [
			...sortedCharacters.slice(currentIndexInSorted),
			...sortedCharacters.slice(0, currentIndexInSorted)
		];

		// Now map over the orderedCharacters instead of encounter.characters
		// First entry is always opened automatically
		return (
			<div key={encounter.id + "buttons"} id="character-stack" className='character-stack'>
			{orderedCharacters.map((char, index) => (
				<CharacterButton 
					key={char.id+index} 
					chardef={char} 
					db={db}
					encounter={encounter}
					setEncounter={setEncounter}
					index={index}
					removeCharacter={removeCharacter}
					characters={orderedCharacters}
				/>
			))}
			</div>
		);
	};

		
	if (isLoading) {
		return <div>Loading...</div>;
	}

	const roundCounter = (
		!isNaN(encounter.currentRound) && <div className='round-number'>Round: {encounter.currentRound}</div>
	)

	const turnStep = () => 
	{
		if (!encounter.characters) { return null; }
		if (!encounter.characters.length === 0) { return null; }
		return (
			<div className='round-number'>({1+encounter.currentCharacterIndex}/{encounter.characters.length})</div>
		)
	}

	const renderRollHistory = () => {
		return rollHistory.map((roll,index) => 
		{
			return <DiceRendererComponent key={index} rollResult={roll} />
		}
	)}

	const qual = db.data.gameSystem == "silcer" ? ["statblock", "skills"] : ["statblock", "hp"];
	const categoryOptions = generateDropdownOptions({categoryID:"monsters",qualifyPath:qual});
	const propDef = { placeholder: "Add Monster" };
	const monsterPicker = () => 
	{
		if (!db.editMode) { return null; }

		return <SearchableDropdown
			initialValue={'unset'}
			options={categoryOptions}
			onUpdateValue={(pathObj, value) => { pickMonster(value) }}
			propDef={propDef}
		/>
	}

	const scrollClass = db.data.gameSystem == "silcer" ? "roll-history-row" : "roll-history"; 

	const atFirst = encounter.currentRound === 0 && encounter.currentCharacterIndex === 0; 
	return (
		<React.Fragment key={encounter?.id}>
			<div id="roll-history-header" className="roll-history-header">
				<button className='roll-history-reset' onClick={()=>clearRollHistory()}>Clear History</button>
			</div>
			<div id="roll-history" className={scrollClass} ref={rollHistoryRef}>
				{renderRollHistory()}
			</div>
			<div className='encounter-menu-bar'>
				<div className='turn-buttons'>
					<ButtonNav disabled={atFirst}  onClick={() => advanceTurn(-1)} label={"<"} />
					<div>Turn</div>
					<ButtonNav onClick={() => advanceTurn(1)} label={">"} />	
				</div>
				<div className='simple-row gap5'>
					{roundCounter}{turnStep()}
				</div>
				{monsterPicker()}
				{db.editMode && <button className='encounter-dupe' onClick={duplicateEncounter}>{'Duplicate'}</button>}
				<button className='encounter-reset' onClick={resetEncounter}>{'RESET'}</button>
			</div>
			{encounter && characterButtons()}
		</React.Fragment>
	);
};

export default EncounterComponent;
