import { titleCase } from "./silcerUtils";

// Define skill categories and their display names
const skillCategories = [
    { key: "strength", label: "Strength-Based Archetypes" },
    { key: "coordination", label: "Coordination-Based Archetypes" },
    { key: "insight", label: "Insight-Based Archetypes" },
    { key: "engagement", label: "Engagement-Based Archetypes" },
    { key: "leadership", label: "Leadership-Based Archetypes" },
    { key: "risk", label: "Risk-Based Archetypes" },
];

// Utility: Format all skills for an archetype
const formatSkills = (archetype) => {
    const skills = {
        strength: archetype.strength,
        coordination: archetype.coordination,
        insight: archetype.insight,
        engagement: archetype.engagement,
        leadership: archetype.leadership,
        risk: archetype.risk,
    };

    return Object.entries(skills)
        .filter(([, value]) => value > 1) // Only include skills with a value > 1
        .map(([skill, value]) => `${titleCase(skill)}:${value}`)
        .join(" ");
};

// Determine the dominant skill for each archetype
const getDominantSkill = (archetype) => {
    const skills = {
        strength: archetype.strength,
        coordination: archetype.coordination,
        insight: archetype.insight,
        engagement: archetype.engagement,
        leadership: archetype.leadership,
        risk: archetype.risk,
    };

    // Sort skills by value (descending) and then by predefined order
    return Object.entries(skills)
        .sort(([keyA, valueA], [keyB, valueB]) =>
            valueB - valueA || skillCategories.findIndex(cat => cat.key === keyA) - skillCategories.findIndex(cat => cat.key === keyB)
        )[0][0]; // Return the key of the highest skill
};

// Open the Archetype Manifest in a new tab
export const openArchetypeManifest = (db) => {
    const archetypes = db.data.archetypes;
    if (!archetypes) {
        console.error("No archetypes found in the database.");
        return;
    }

    // Group archetypes by dominant skill
    const groupedArchetypes = skillCategories.map(({ key, label }) => ({
        label,
        archetypes: Object.values(archetypes).filter(arch => getDominantSkill(arch) === key),
    }));

    // Generate HTML content
    const manifestContent = groupedArchetypes
        .map(({ label, archetypes }) => {
            if (archetypes.length === 0) return ""; // Skip empty groups

            const archetypesContent = archetypes
                .map((archetype) => {
                    const topSkills = formatSkills(archetype); // Format all skills for display
                    return `
                    <table border="1">
                        <tr>
                            <td><h3>${archetype.name}</h3></td>
                            <td style="text-align: right;">${topSkills}</td>
                        </tr>
                        <tr>
                            <td colspan="2">${archetype.desc}</td>
                        </tr>
                        ${archetype.examp
                            ? `<tr><td colspan="2">${archetype.examp.replace(/\n/g, "<br>")}</td></tr>`
                            : ""
                        }
                    </table>
                    `;
                })
                .join("");

            return `<h2>${label}</h2>${archetypesContent}`;
        })
        .join("");

    // Create and open a new tab for the manifest
    const newTab = window.open();
    if (newTab) {
        newTab.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Archetype Manifest</title>
                <style>
                    h3 {
                        margin: 0px;
                    }
                    table {
                        line-height: 1;
                        vertical-align: bottom;
                        width: 100%;
                        margin: 0px;
                    }
                </style>
            </head>
            <body>
                ${manifestContent}
            </body>
            </html>
        `);
        newTab.document.close();
    }
};

// React Component
const ArchetypeManifest = () => {
    const db = useDatabase();

    // Automatically open the manifest on mount
    React.useEffect(() => {
        openArchetypeManifest(db);
    }, [db]);

    return null;
};

export default ArchetypeManifest;
