import './App.css'
import React, { useState } from 'react';
import LoginForm from './components/LoginForm/LoginForm.jsx';
import { DatabaseProvider } from './DatabaseProvider.jsx';
import { RemoteTabProvider } from './contexts/RemoteTab/RemoteTabProvider.jsx';
import Editor from './components/Editor/Editor.jsx';
import { ImageProvider } from './ImageCacheContext.jsx';
import { RollHistoryProvider } from './contexts/RollHistory/RollHistoryContext.jsx';
import { SearchProvider } from './contexts/SearchContext.jsx';
import KeyboardManager from './KeyboardManager.jsx';
import { DeviceTypeProvider } from './contexts/DeviceType/DeviceTypeContext.jsx';
import FilePicker from './components/Filelist/FilePicker.jsx'

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);

	const handleLoginSuccess = () => {
		setIsLoggedIn(true);
	};

	const handleFileSelect = (filename) => {
		setSelectedFile(filename);
	};

	return (
		<>
			{isLoggedIn ? (
				selectedFile ? (
					<DeviceTypeProvider>
						<DatabaseProvider filename={selectedFile}>
							<SearchProvider>
								<ImageProvider>
									<RemoteTabProvider>
										<RollHistoryProvider>
											<KeyboardManager />
											<Editor />
										</RollHistoryProvider>
									</RemoteTabProvider>
								</ImageProvider>
							</SearchProvider>
						</DatabaseProvider>
					</DeviceTypeProvider>
				) : (
					<FilePicker onFileSelect={handleFileSelect} />
				)
			) : (
				<LoginForm onLoginSuccess={handleLoginSuccess} />
			)}
		</>
	);
}

export default App;
