import React, { useState, useContext } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { statblock } from '../../PropertyDefs.js';
import './StatBlock.css';
import DiceRendererComponent from '../DiceRenderer/DiceRenderer.jsx';
import { RollHistoryContext } from '../../contexts/RollHistory/RollHistoryContext.jsx';
import ParseDNDBeyond from '../../util/ParseDNDBeyond.js';
import ButtonExpand from '../Buttons/ButtonExpand.jsx';
import editStats from './editStats.jsx';
import { smartLog } from "../../util/smartLog.js";
import diceRoller from '../../util/DiceRoller/DiceRoller.js'; // In-line stat roller

const Dnd5EStatBlockComponent = ({ pathObj, sendResultToHistory, autoExpand }) => {
	const db = useDatabase();
	const [selectedCategory, setSelectedCategory] = useState(''); // State to keep track of the selected category
	const [diceRollResult, setDiceRollResult] = useState(null); // New state to store the roll result
	const [showButtons, setShowButtons] = useState(false);

	const { addRoll, rollHistory } = useContext(RollHistoryContext);
	const monsterPath = [...pathObj.path];
	const monster = db.getPropertyByPath(monsterPath);
	const fullPath = [...pathObj.path, pathObj.propertyID];
	const statblockObject = db.getPropertyByPath(fullPath);
	if (!statblockObject) { return null; }

	// Group properties by category
	const groupedProperties = statblock.reduce((acc, prop) => {
		const category = prop.category || 'Uncategorized';
		if (!acc[category]) {
			acc[category] = [];
		}
		acc[category].push(prop);
		return acc;
	}, {});

	// Function to render a single stat block item
	const renderStatBlockButton = (prop) => 
	{
		let value = statblockObject[prop.id];

		if (value == null)
		{
			// No value saved to database if it's 0, replace here
			// console.log("no value for",prop.id)
			// return null;
			value = 0;
		}

		// Show actual mod value on base since they aren't dynamic (HP, AC, etc) 
		let showMod = prop.showMod;
		let clickable = prop.clickable;

		// Light up proficient/expert skills
		let extraClass = "";
		const proficiency = statblockObject[prop.id]
		if (prop.type === "profLevel")
		{
			if (proficiency == 1)
			{
				extraClass = "proficient";
			}
			if (proficiency == 2)
			{
				extraClass = "expertise";
			}
		}

		const buttonClassName = extraClass + " stat-button";
		const labelClassName = extraClass + " stat-button-label";
		const label = prop.id !== "special" ? prop.name : "";
		// Don't roll the showMod buttons (they aren't variable)
		return (
			<div onClick={clickable?() => simpleRoll(prop,statblockObject,pathObj,monster,sendResultToHistory,setDiceRollResult) : null } className={buttonClassName} key={prop.id}>
				{label && <div className={labelClassName}>{label}</div>}
				{showMod && <div className={labelClassName}> {value}</div>}
			</div>
		)
	};

	function simpleRoll(prop,statblockObject,pathObj,monster,sendResultToHistory,setDiceRollResult)
	{
		const myBaseMod = Number.parseFloat(statblockObject[prop.ability]);
		if (myBaseMod == null)
		{
			console.log(prop.id+" cannot be rolled");
			return;
		}
		// Skill roll can add proficiency. 
		// If the id and ability are the same, we're just rolling directly against the skill, 
		// otherwise, it's a saving throw that can have proficiency
		const profInSkill = (prop.id !== prop.ability) ? statblockObject[prop.id] || 0 : 0;
		const profBonus = statblockObject['prof'];
		const proficiency = Math.max(0, profInSkill * profBonus);
		const totalBonus = myBaseMod + proficiency;
		let desc = "";
		if (profInSkill === 0.5) { desc = "half-proficent"};
		if (profInSkill === 1) { desc = "proficent"};
		if (profInSkill === 2) { desc = "expert"};

		// Pass a secret note with the full breakdown of the roll for the tooltip
		const fromprof = proficiency > 0 ? ", "+formatBonus(proficiency)+" from "+desc : "";
		const underTheHood = formatBonus(totalBonus)+" ("+formatBonus(myBaseMod)+" from "+prop.ability+fromprof+")";
		smartLog({cat:"dice"},underTheHood);

		const rollName = prop.id.startsWith("save") ? prop.name+" Save" : prop.name;
			
		const key = prop.id+pathObj[pathObj.length-1];
		const rollDef = {
			id: key,
			dice: [
				{
					rollType: "simple",
					count: 1,
					die: 20,
					mod: totalBonus
				}
			],
			rollerName: monster.name, 
			roller: monster,
			name: rollName,
			desc: desc,
			underTheHood: underTheHood
		}
		
		// Call the diceRoller function and pass the callback to handle results
		// Always roll with adv so it rolls twice. Just take the first value if it's a flat roll
		// TODO: Add modifier keypress (and solve for a mobile solution) to actually roll adv/disadv 
		// and highlight proper result
		diceRoller(rollDef, "adv", (results) => {
			// Handle the results of the dice roll, e.g., by setting state
			if (!sendResultToHistory)
			{
				setDiceRollResult(results);
			}
			else
			{
				addRoll(results);
			}
		});
	}

	function formatBonus(val)
	{
		if (val > 0) { return "+"+val; }
		return val;
	}
		
	function updateValue(pathObj, value) {
		// Most of these values are specifically numbers. Special is text, and CR has to do a special math for 1/8, 1/4, 1/2
		var number = Number.parseInt(value);
		if (pathObj.propertyID == "cr")
		{
			// Special case: convert that fraction into a decimal for storage
			if (value.includes("/"))
			{
				const parts = value.split("/");
				number = parts[0]/parts[1];
			}
		}
		console.log(value,number,pathObj)
		const finalPath = [...pathObj.path, pathObj.propertyID];
		db.updateDB(finalPath, number || value);
	}

	// Function to render the buttons within a category row
	const renderCategoryRow = (category, properties, sorted, split = false) => {
		
		// Sort properties alphabetically if requested
		const sortedProperties = sorted ? properties.sort((a, b) => a.name.localeCompare(b.name)) : properties;

		// Check if any property in the category has data (is not undefined)
		const hasData = sortedProperties.some(prop => statblockObject[prop.id] !== undefined);

		// Split the properties array into two if required
		const splitIndex = split ? Math.ceil(sortedProperties.length / 2) : sortedProperties.length;
		const firstHalf = sortedProperties.slice(0, splitIndex);
		const secondHalf = split ? sortedProperties.slice(splitIndex) : [];

		// Only render the category if there is data
		const className = "stat-field-" + category + " stat-row";
		return (
			<div className={className} key={category}>
				<div className='stat-row'>
					{firstHalf.map(prop => renderStatBlockButton(prop))}
				</div>
				{split && (
					<div className='stat-row'>
						{secondHalf.map(prop => renderStatBlockButton(prop))}
					</div>
				)}
			</div>
		);
	};

	// Function to render a single category with a heading
	const renderCategory = (category, groupedProperties, sorted = false, split = false) => {
		// Find the def to see if we have a custom title
		const catDef = statblock.find(o=>o.category===category);
		if (!catDef) { console.warn("Can't find category def",category); } 
		const showTitle = !catDef.hideTitle;
		return (
			<div className='category-row' key={category}>
				{showTitle && <div className='edit-stat-row-header'>{category}</div>}
				{renderCategoryRow(category, groupedProperties[category], sorted, split)}
			</div>
		)
	}

	function showAllStats(groupedProperties) {
		return (
			<div className='stats-container'>
				<div className='first-row'>
					{renderCategory("Base", groupedProperties)}
				</div>
				<div className='first-row'>
					{renderCategory("Abilities", groupedProperties)}
					{renderCategory("Saves", groupedProperties)}
				</div>
				{renderCategory("Skills", groupedProperties, true, true)}
				{/* {renderCategory("Special", groupedProperties)}  */}
			</div>
		);
	}
	
	function toggleStats()
	{
		setShowButtons(!showButtons);
	}
	
	// When editing: text input fields for inputting number values, dropdowns for profLevel
	// When viewing: buttons that trigger actual rolls

	function attemptImport(event)
	{
		const str = event.target.value;
		const updatedMonster = ParseDNDBeyond(str,monster,db);
		if (updatedMonster)
		{
			// Success! Clear the field
			event.target.value = "";
		}
	}

	const label = "Statblock\u2003"+(showButtons ? "⇘" : "⇒")+(statblockObject.special ? "\u2003"+statblockObject.special : ""	);

	if (db.editMode)
	{
		return (
			<div className='full-column'>
				<ButtonExpand label={label} onClick={toggleStats}/>
				{showButtons && editStats(groupedProperties,updateValue,pathObj)}
				<div id="dndbeyond-import" className="dndbeyond-import">
					Import from DNDBeyond:
					<textarea
						onBlur={attemptImport}
					/>
				</div>
			</div>
		) 
	}
	else
	{
		return (
			<div className='full-column'>
				<ButtonExpand label={label} onClick={toggleStats}/>
				{(showButtons /*|| autoExpand*/) && showAllStats(groupedProperties)}
				{diceRollResult && (
					<DiceRendererComponent rollResult={diceRollResult}/>
				)}
			</div>
		)
	}
}

export default Dnd5EStatBlockComponent;
