import React, { useEffect } from 'react';
import './NumberModal.css'; // You can reuse the same styles or adjust them as needed

const LabelModal = ({ labelDef, onCancel }) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                onCancel(); // Call the onCancel function when Escape is pressed
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onCancel]);

    return (
        <div className="modal-backdrop">
            <div className="modal">
                <div className="label-display">
                    {labelDef.label || "No label provided"}
                </div>
                <div className="modal-actions">
                    <button onClick={onCancel}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default LabelModal;
