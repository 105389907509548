import React from "react";
import { titleCase } from "./silcerUtils";

const StatRenderer = ({ 
    statName, 
    statValue, 
    skills, 
    resilienceLabel, 
    resilienceValue, 
    resilienceMax, 
    renderSkill, 
    onIncrementResilience, 
    onDecrementResilience 
}) => {
    
    return (
        <div className="stat-section">
            <div className="stat-header">
                <span>{titleCase(statName)}</span>
                <span className="stat-header-number">{statValue}</span>
            </div>
            {skills.map(([skillName, skillRank]) => renderSkill({skill:skillName, rank:skillRank}))}
            <div className="resilience-value">
                <span className="resilience-label">{titleCase(resilienceLabel)}</span>
                <span className="simple-row">
                    <button
                        onClick={onDecrementResilience}
                        className="resilience-button decrement"
                    >
                        -
                    </button>
                    <span className="resilience-number">
                        {resilienceValue}/{resilienceMax}
                    </span>
                    <button
                        onClick={onIncrementResilience}
                        className="resilience-button increment"
                        disabled={resilienceValue >= resilienceMax}
                    >
                        +
                    </button>
                </span>
            </div>
        </div>
    );
};

export default StatRenderer;
