import React, { useEffect } from "react";
import "./FullScreenModal.css";

const FullScreenModal = ({ isOpen, buttons, onClose, onButtonClick }) => {
    // Handle Esc key to close modal
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && isOpen) {
                onClose();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, onClose]);

    // Render nothing if the modal is not open
    if (!isOpen) return null;

    return (
        <div className="fullscreen-modal">
            <div className="modal-content">
                <div className="button-column">
                    {buttons.map(({ id, label }) => (
                        <button
                            key={id}
                            className="modal-button"
                            onClick={() => onButtonClick(id)}
                        >
                            {label}
                        </button>
                    ))}
                </div>
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default FullScreenModal;
