import React from 'react';
import SilcerStatBlock from './SilcerStatBlock';
import Dnd5EStatBlock from './Dnd5EStatBlock';
import { smartLog } from '../../util/smartLog';

const StatBlock = ({ pathObj, gameSystem, ...props }) => {
    switch (gameSystem) {
        case 'silcer':
            return <SilcerStatBlock pathObj={pathObj} {...props} />;
        case 'dnd5e':
            return <Dnd5EStatBlock pathObj={pathObj} {...props} />;
        default:
            return <Dnd5EStatBlock pathObj={pathObj} {...props} />;
    }
};

export default StatBlock;