import React from "react";
import "./ArchetypePicker.css";
import { useDatabase } from "../../DatabaseProvider.jsx";
import { propertyDefs } from "../../PropertyDefs.js";
import SkillColumn from "./SkillColumn.jsx";

const ArchetypePicker = ({ statblock, onUpdate, scrollRefs, scrollPositions }) => {
    const db = useDatabase();

    const renderArchetypes = (skillId) => {
        const skillArchetypes = Object.values(db.data.archetypes)
            .filter((archetype) => archetype[skillId] == 3)
            .sort((a, b) => b[skillId] - a[skillId]); // Descending order by skill relevance

        return skillArchetypes.map((archetype) => (
            <button
                key={archetype.id}
                className={`archetype-button ${statblock.archetype === archetype.id ? "owned" : ""}`}
                onClick={() => onUpdate(archetype.id)}
            >
                <div className="archetype-name">{archetype.name}</div>
                <div className="archetype-desc">{archetype.desc}</div>
            </button>
        ));
    };

    return (
        <div className="archetype-picker">
            <div className="archetype-grid">
                {propertyDefs.silcerSkill.map((skill) => (
                    <SkillColumn
                        key={skill.id}
                        skill={skill}
                        scrollRefs={scrollRefs}
                        scrollPositions={scrollPositions}
                        onScroll={(e, skillId) => (scrollPositions.current[skillId] = e.target.scrollTop)}
                        contentRenderer={renderArchetypes}
                    />
                ))}
            </div>
        </div>
    );
};

export default ArchetypePicker;
