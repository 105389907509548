import React, { useState, useEffect, useRef } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import './SearchableDropdown.css';
import Tooltip from '../Tooltip';

const SearchableDropdown = ({ className, pathObj, label, initialValue, options, onUpdateValue, propDef, labelOverride, jumpTo }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState('');
	const [filteredEntries, setFilteredEntries] = useState(options);
	const dropdownRef = useRef(null);
	const filterInputRef = useRef(null);
	const db = useDatabase();
	
	// Handle the Escape key press
	useEffect(() => 
	{
		const handleKeyDown = (event) => 
		{
			if (event.key === 'Escape') {
				// If the filter string is not empty, clear it.
				// If it is already empty and the dropdown is open, close the dropdown.
				if (filter) {
					setFilter('');
				} else if (isOpen) {
					setIsOpen(false);
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		// Cleanup the event listener on component unmount
		return () => 
		{
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [filter, isOpen]); // Add filter and isOpen to the dependency array


	// Handle clicking outside of the dropdown to close it
	useEffect(() => 
	{
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [dropdownRef]);

	// Filter the entries whenever the filter text changes
	useEffect(() => 
	{
		const regex = new RegExp(filter, 'i');
		const filtered = options.filter((entry) => regex.test(entry.name));
		setFilteredEntries(filtered);
	}, [filter, options]);

	// Focus on the filter input when the dropdown opens
	useEffect(() => 
	{
		if (isOpen && filterInputRef.current) 
		{
			filterInputRef.current.focus();
			filterInputRef.current.select();
		}
	}, [isOpen]);

	// When an entry is selected, call the onUpdateValue callback and close the dropdown
	const handleSelect = (value) => 
	{
		onUpdateValue(pathObj, value);
		setIsOpen(false);
	};

	// options is an array of {id,name} only. This interface is mostly agnostic to the database itself 
	// (apart from checking it for editMode)
	// But for the sake of beauty, I want to access the tooltips and placeholders in the propertyDefs
	// Prefer the placeholder name (same as would show up if this were a blank text field)

	// If initialValue is undefined or not found, default to a placeholder
    const initialOption = initialValue ? options.find((o) => o.id === initialValue) : null;
    const initialLabel = labelOverride || (initialOption ? initialOption.name : (propDef.placeholder || propDef.name));
	const arrow = db.editMode ? "▾" : "";// (isOpen ? "▼" : "►") : "";

	function tooltip(entry) { 
		var tt = entry.tooltip;
		var desc = entry.desc;
		var summary = entry.summary;
		var level = entry.level ? entry.level : null;
		// Create an array with the values
		const values = [level, tt, desc, summary];
		// Filter out null or undefined values
		const nonNullValues = values.filter(value => value != null);
		// Join the non-null values with newline characters
		var output = nonNullValues.join('  ||  ');
		return output;
	}

	const editable =
	(
		<div className="searchable-dropdown editor-field-row" ref={dropdownRef}>
			<button className={"dropdown-toggle "+className} onClick={() => setIsOpen(!isOpen)}>
				{initialLabel}<span className="dropdown-toggle-arrow">{arrow}</span>
			</button>

			{isOpen && (
				<div className="dropdown-menu">
					<input
						ref={filterInputRef}
						type="text"
						placeholder="Filter..."
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
						className="dropdown-filter"
					/>
					{filteredEntries.map((entry,index) => (
						<Tooltip key={entry.id+index} text={tooltip(entry)}>
							<button
								key={entry.id}
								onClick={() => handleSelect(entry.id)}
								className="dropdown-option"
							>								
							{entry.name}
							</button>
						</Tooltip>
					))}
				</div>
			)}
		</div>
	)

	const jumpToSrc = () => 
	{
		if (jumpTo)
		{
			db.setCurrentEntry(jumpTo);
		}
	}

	const readonly = 
	(
		<div className={"searchable-dropdown editor-field-row "+className} ref={dropdownRef} onClick={jumpToSrc}>
			<div className="dropdown-readonly">
				{initialLabel}
			</div>
		</div>
	)

	// If not in editMode, don't render anything if the field hasn't been set
	const labelClassname = "dropdown-label";
	return (
		<div key={label + initialValue} className='editor-field-row'>
		  {(db.editMode || initialValue) && label && <div className={labelClassname}>{label}</div>}
		  {db.editMode ? editable : (initialValue && readonly)}
		</div>
	  );
};

export default SearchableDropdown;
