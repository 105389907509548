import { titleCase, getDieType, getStatBySkill } from "./silcerUtils";

export const openCharacterSheet = (db, character, derivedStats, resilienceValues) => {
    const { name, statblock } = character;

    const sheetContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${titleCase(name)}'s Character Sheet</title>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
            <style>
                body {
                    font-family: "Agdasima", serif;
                    margin: 0;
                    padding: 10px;
                    line-height: 1;
                    background: #fff;
                    color: #000;
                }
                h1, h2, h3 {
                    margin: 0;
                    padding: 10px 0;
                }
                .top-banner {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 2px solid #000;
                    padding: 10px 0;
                    margin-bottom: 20px;
                }
                .top-banner div {
                    flex: 1;
                    text-align: center;
                }
                .stats {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                }
                .stat-block {
                    border: 1px solid #000;
                    padding: 5px;
                    border-radius: 1em;
                    flex: 1;
                    min-width: 100px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
                }
                .stat-columns {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5em;
                }
                .stat-left, .stat-right {
                    flex: 1;
                }
                .stat-left {
                    border-right: 1px solid #ddd;
                    padding-right: 10px;
                }
                .stat-right {
                    padding-left: 10px;
                }

                .stat-item {
                    padding-bottom: 1em;
                }

                .stat-name {
                    font-size: 1.5em;
                    font-weight: bold;
                    text-align: center;
                }
                .stat-value {
                    font-size: 2em;
                    font-weight: bold;
                    text-align: center;
                }
                .resilience-name {
                    font-size: 1.5em;
                    font-weight: bold;
                    text-align: center;
                }
                .resilience-value {
                    font-size: 2em;
                    font-weight: bold;
                    text-align: center;
                }

                .skill-item {
                    padding-bottom: 1em;
                }
                .skill-name {
                    font-size: 1.5em;
                    font-weight: bold;
                    text-align: center;
                }
                .skill-rankAndRoll {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5em;
                }
                .skill-rank {
                    font-size: 2em;
                    font-weight: bold;
                    flex: 1;
                    text-align: right;
                }
                .skill-roll {
                    font-weight: normal;
                    font-size: 1em;
                    flex: 1; /* Takes all remaining space */
                    text-align: left;
                }
                .augment-notes {
                    font-size: 0.8em;
                    text-align-last: left;
                }

                .augments-block {
                    flex-wrap: wrap;
                    display: flex;
                    border: 1px solid #000;
                    padding: 1em;
                    margin-top: 1em;
                    border-radius: 1em;
                    min-height: 100px;
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
                }

                .augment-title {
                    text-align: center;
                    text-decoration: underline;
                }
                .augment-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
                .augment-list li {
                    break-inside: avoid; /* Prevent awkward breaks between list items */
                    margin-bottom: 1em;
                }

                .augment-columns {
                    display: flex;
                    gap: 1em;
                    flex: 1;
                }

                .augment-column {
                    flex: 1; /* Distribute columns evenly */
                    min-width: 200px;
                }

                .augment-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                .background-notes {
                    margin-top: 40px;
                    padding: 10px;
                    border: 1px solid #000;
                    min-height: 200px;
                }
            </style>
        </head>
        <body>
            <div class="top-banner">
                <div><strong>Name:</strong> ${titleCase(name)}</div>
                <div><strong>Level:</strong> ${derivedStats.Level}</div>
                <div><strong>Model:</strong> ${statblock.model}</div>
                <div><strong>Archetype:</strong> ${statblock.archetype || "N/A"}</div>
                <div><strong>Player Name:</strong></div>
            </div>
            <div class="section stats">
                ${generateStatBlock(db, "Physical", derivedStats.Level, derivedStats.physical, resilienceValues.integrity, statblock.skills, statblock.augments, ["Strength", "Coordination"], "Integrity")}
                ${generateStatBlock(db, "Mental", derivedStats.Level, derivedStats.mental, resilienceValues.focus, statblock.skills, statblock.augments, ["Insight", "Engagement"], "Focus")}
                ${generateStatBlock(db, "Social", derivedStats.Level, derivedStats.social, resilienceValues.standing, statblock.skills, statblock.augments, ["Leadership", "Risk"], "Standing")}
            </div>
            <div class="augments-block">
                <div class="augment-columns">
                    <div class="augment-column">
                        <h3 class="augment-title">Passive Augments</h3>
                        <ul class="augment-list">
                            ${statblock.augments
                        .map(augmentId => db.data.augments[augmentId])
                        .filter(augment => augment.type === "passive")
                        .map(augment => `
                                    <li>
                                        <strong>${augment.name}</strong> (+${augment.rank} ${titleCase(getStatBySkill(augment.skill)) }, ${titleCase(augment.type)})<br>
                                        ${augment.desc}
                                    </li>`).join('')}
                        </ul>
                    </div>

                    <div class="augment-column">
                        <h3 class="augment-title">Active Augments</h3>
                        <ul class="augment-list">
                            ${statblock.augments
                        .map(augmentId => db.data.augments[augmentId])
                        .filter(augment => augment.type === "active")
                        .map(augment => `
                                    <li>
                                        <strong>${augment.name}</strong> (+${augment.rank} ${titleCase(augment.skill)}, ${titleCase(augment.type)})<br>
                                        ${augment.desc}
                                    </li>`).join('')}
                        </ul>
                    </div>

                    <div class="augment-column">
                        <h3 class="augment-title">Quantum Augments</h3>
                        <ul class="augment-list">
                            ${statblock.augments
                        .map(augmentId => db.data.augments[augmentId])
                        .filter(augment => augment.type === "quantum")
                        .map(augment => `
                                    <li>
                                        <strong>${augment.name}</strong> (+${augment.rank} ${titleCase(augment.skill)}, ${titleCase(augment.type)}. 
                                        Target: ${titleCase(augment.target)}${augment.duration ? " / "+titleCase(augment.duration) : ""})<br>
                                        ${augment.desc}
                                    </li>`).join('')}
                        </ul>
                    </div>
                </div>
            </div>


            <div class="background-notes">
                <h3>Background and Notes</h3>
            </div>
        </body>
        </html>
    `;

    const newTab = window.open();
    if (newTab) {
        newTab.document.write(sheetContent);
        newTab.document.close();
    }
};

const generateStatBlock = (db, statName, level, statValue, resilienceValue, skills, augments, skillKeys, resilienceLabel) => {
    const skillDetails = skillKeys.map(skill => {
        const base = skills[skill.toLowerCase()] || 0;

        const skillAugments = augments
            .map(augId => db.data.augments[augId])
            .filter(augment => augment && augment.skill.toLowerCase() === skill.toLowerCase() && ["active", "quantum"].includes(augment.type))
            .map(augment => ({
                name: augment.name,
                rank: augment.rank,
            }));

        const totalBonus = skillAugments.reduce((sum, aug) => sum + parseInt(aug.rank, 10), 0);
        return {
            skill,
            base,
            skillAugments,
            totalBonus,
        };
    });

    const skillToStatMap = {
        strength: "physical",
        coordination: "physical",
        insight: "mental",
        engagement: "mental",
        leadership: "social",
        risk: "social",
    };

    const statAugments = augments
        .map(augId => db.data.augments[augId])
        .filter(augment => {
            if (!augment || augment.type !== "passive") return false;
            const correspondingStat = skillToStatMap[augment.skill.toLowerCase()];
            return correspondingStat === statName.toLowerCase();
        })
        .map(augment => ({
            name: augment.name,
            rank: augment.rank,
        }));

    const statBonus = statAugments.reduce((sum, aug) => sum + parseInt(aug.rank, 10), 0);
    const rawBase = statValue - statBonus;
    const resValue = statValue + level;
    
    return `
        <div class="stat-block">
            <div class="stat-columns">
                <div class="stat-left">

                    <div class="stat-item">
                        <div class="stat-name">
                            ${titleCase(statName)}
                        </div>
                        <div class="stat-value">
                            ${statValue}
                        </div>
                        <div class="augment-notes">
                            ${`+${rawBase} Base<br>`}
                            ${statAugments.length > 0 ? `
                                    ${statAugments.map(aug => `+${aug.rank} ${aug.name}<br>`).join('')}
                                ` : ""}
                        </div>
                    </div>

                    <div class="stat-item">
                        <div class="resilience-name">
                            ${resilienceLabel}
                        </div>
                        <div class="resilience-value">
                        <span style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> / ${resValue}
                        </div>
                    </div>

                </div>
                <div class="stat-right">
                    ${skillDetails.map(skill => `
                        <div class="skill-item">
                            <div class="skill-name">
                                ${titleCase(skill.skill)}
                            </div>
                            <div class="skill-rankAndRoll">
                                <div class="skill-rank">
                                    ${skill.base}
                                </div>
                                <div class="skill-roll">(1d${getDieType(skill.base)}${skill.totalBonus > 0 ? `+${skill.totalBonus}` : ""})</div>
                            </div>
                            
                            <div class="augment-notes">
                                ${skill.skillAugments.length > 0 ? `${skill.skillAugments.map(aug => `+${aug.rank} ${aug.name}<br>`).join('')}` : ""}
                            </div>
                        </div>
                    `).join('')}
                </div>
            </div>
        </div>
    `;
};
