import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './History.css';
import { useDatabase } from '../../DatabaseProvider';
import ButtonNav from '../Buttons/ButtonNav';

const HistoryComponent = () => {
	const db = useDatabase();
	const historyRef = useRef(null); // Reference for the history container
	const [history, setHistory] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(-1);

	useLayoutEffect(() => {
		// Scroll to the far right after the DOM updates
		if (historyRef.current) {
			historyRef.current.scrollLeft = historyRef.current.scrollWidth;
		}
	}, [history]); // Trigger only when the history changes

	useEffect(() => {
		if (currentIndex >= 0) {
			const entry = JSON.parse(JSON.stringify(history[currentIndex]));
			entry.fromHistory = true;
			db.setCurrentEntry(entry);
		}
	}, [currentIndex]);

	useEffect(() => {
		if (db.currentEntry && (history.length === 0 || db.currentEntry !== history[currentIndex])) {
			if (!db.currentEntry.categoryID || !db.currentEntry.entryID) {
				return;
			}
			if (!db.currentEntry.fromHistory) {
				const existingIndex = history.findIndex(
					(item) =>
						item.categoryID === db.currentEntry.categoryID &&
						item.entryID === db.currentEntry.entryID
				);

				let newHistory = [...history];
				if (existingIndex !== -1) {
					// Move the existing entry to the end
					const [existingEntry] = newHistory.splice(existingIndex, 1);
					newHistory.push(existingEntry);
				} else {
					// Add a new entry to the history
					newHistory = newHistory.slice(0, currentIndex + 1);
					newHistory.push(db.currentEntry);
				}

				setHistory(newHistory);
				setCurrentIndex(newHistory.length - 1);
			}
		}
	}, [db.currentEntry]);

	const getLabel = (entry) => {
		const category = db.data[entry.categoryID];
		const item = category?.[entry.entryID];
		const name = item?.name || `Unknown (${entry.entryID})`;
		return name.length > 50 ? `${name.slice(0, 47)}...` : name;
	};

	const navigate = (dir) => {
		let newIndex = currentIndex + dir;
		if (newIndex >= 0 && newIndex < history.length) {
			setCurrentIndex(newIndex);
		}
	};

	const selectEntry = (index) => {
		setCurrentIndex(index);
		const buttons = document.querySelectorAll('.history-entry-button');
		if (buttons[index]) {
			buttons[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
		}
	};

	return (
		<div className='history-button-row'>
			<ButtonNav disabled={currentIndex <= 0} onClick={() => navigate(-1)} label={'<'} />
			<ButtonNav disabled={currentIndex >= history.length - 1} onClick={() => navigate(1)} label={'>'} />

			{/* Render individual history buttons */}
			<div className="history-entries" ref={historyRef}>
				{history.map((entry, index) => (
					<button
						key={index}
						className={`history-entry-button ${index === currentIndex ? 'active' : ''}`}
						onClick={() => selectEntry(index)}
					>
						{getLabel(entry)}
					</button>
				))}
			</div>
		</div>
	);
};

export default HistoryComponent;
